import { render, staticRenderFns } from "./comp_OfferItem.vue?vue&type=template&id=6905858d&scoped=true"
import script from "./comp_OfferItem.vue?vue&type=script&lang=js"
export * from "./comp_OfferItem.vue?vue&type=script&lang=js"
import style0 from "./comp_OfferItem.vue?vue&type=style&index=0&id=6905858d&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6905858d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VHover } from 'vuetify/lib/components/VHover';
installComponents(component, {VBtn,VCard,VHover})
